<template>
  <div
    class="wrapper"
    style="
      background: linear-gradient(99.9deg, #0273c3, #004c91 50.52%, #a92374);
    "
  >
    <div class="card">
      <div v-if="!confirmed">
        <h2 style="text-align: center; width: 100%">
          Tem certeza que deseja parar de receber mensagens?
        </h2>
        <div class="btnDiv">
          <z-btn
            text="Confirmar"
            primary
            color="primary"
            @click="confirmOptOut"
          />
        </div>
      </div>
      <div v-else>
        <h2 style="text-align: center; width: 100%">
          Tudo bem, não iremos mais enviar email e sms para você!
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import ApiClient from "@/services/ApiClient";

export default {
  data() {
    return {
      confirmed: false,
      http: new ApiClient(),
      optOutObj: {}
    };
  },
  created(){
    const key = this.$route.query.key;
    console.log(key);
    this.optOutObj = JSON.parse(atob(key));
  },
  methods: {
    async confirmOptOut() {
      console.log(this.$route.query);
      const res = await this.http.post("optOutOpen/add", this.optOutObj);

      if (res) {
        this.confirmed = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.wrapper {
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100vh !important;
}

.card {
  background: white;
  border-radius: 15px;
  width: 450px;
  padding: 15px;
}

.primary {
  background-color: #1976d2 !important;
}

.btnDiv {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.btnDiv button {
  width: 100px;
  margin: 15px;
}

.wrapper {
  // padding: $z-s-1 $z-s-2;
  padding: $z-s-1;
  height: calc(100vh - 48px);
  &__card {
    padding: $z-s-1;
    background: #ffffff;
    border-radius: 15px;
    height: 100%;
    overflow-y: auto;

    @include trackScrollBar;

    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: $z-s-1;
      // border: 3px solid blue;
      min-height: 100%;
      max-height: 100%;

      &__item {
        flex: 1 1 230px;
        display: flex;
        // border: 1px solid red;
        flex-direction: column;
        gap: 1rem;
        max-height: 100%;
      }
    }
  }
  @media (max-width: 750px) {
    padding: $z-s-1;
  }
}
</style>
