var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "wrapper",
      staticStyle: {
        background:
          "linear-gradient(99.9deg, #0273c3, #004c91 50.52%, #a92374)",
      },
    },
    [
      _c("div", { staticClass: "card" }, [
        !_vm.confirmed
          ? _c("div", [
              _c(
                "h2",
                { staticStyle: { "text-align": "center", width: "100%" } },
                [_vm._v(" Tem certeza que deseja parar de receber mensagens? ")]
              ),
              _c(
                "div",
                { staticClass: "btnDiv" },
                [
                  _c("z-btn", {
                    attrs: { text: "Confirmar", primary: "", color: "primary" },
                    on: { click: _vm.confirmOptOut },
                  }),
                ],
                1
              ),
            ])
          : _c("div", [
              _c(
                "h2",
                { staticStyle: { "text-align": "center", width: "100%" } },
                [
                  _vm._v(
                    " Tudo bem, não iremos mais enviar email e sms para você! "
                  ),
                ]
              ),
            ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }